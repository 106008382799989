import React from 'react'

import './../styles/pageSpecific/services.scss'
import GeneralPageLayout from '../components/Layouts/GeneralPage'
import MainTextBlock from '../components/UI/MainTextBlock/MainTextBlock'
import SectionContainer from '../components/UI/Containers/SectionContainer'
import SectionHeader from '../components/UI/SectionHeader/SectionHeader'
import SplitTwoToneContainer from './../components/UI/Containers/SplitTwoToneContainer'
import CapabilityVideo from '../components/UI/Capabilities/CapabilityVideo/CapabilityVideo'

import ExpertCapabilities from '../components/UI/Capabilities/ExpertCapabilities/ExpertCapabilities'
import ServiceIcon from './../images/icons/capabilities/InfoTech.inline.svg'

const InformationTechnologyPage = () => {
  const pageInfo = {
    pageType: 'interior',
    title1: 'Information',
    title2: 'Technology',
    breadcrumbs: ['Capabilities'],
    ctaButton: false,
    ctaButtonData: {
      text: '',
      url: '',
    },
  }

  return (
    <GeneralPageLayout pageData={pageInfo}>
      <div className="content">
        {/* Capabilities Video */}
        <SectionContainer
          id="serviceVideo"
          color="white"
          type="pressedEdgeRight"
          classes={['inlineChildren_half', 'capabilitySection']}
        >
          <section>
            <SectionHeader color="navy" alignment="left" size="med">
              Developing Enterprise-Wide <span>Solutions</span>
            </SectionHeader>
            <MainTextBlock>
              <p>
                We collaborate with our government clients to plan, design, and
                integrate enterprise-wide IT service management solutions. We
                recognize that our customers are partners in collaboration and
                encourage them to share their knowledge and expertise to ensure
                sustained success.{' '}
              </p>
            </MainTextBlock>
          </section>
          <CapabilityVideo
            url="https://www.youtube.com/embed/tr7TtaTZcKE?yt:cc=on"
            title="Precise Information Technology Informational Video"
          />
        </SectionContainer>

        {/* Why Us */}
        <SplitTwoToneContainer id="" classes={['hugMiddleLine']}>
          <section>
            <h3>
              <span>
                Our information technology experts hold the requisite DoDD 8570
                certifications &amp; work according to this designation.{' '}
              </span>
            </h3>
          </section>
          <section>
            <h3>
              We enable the shift of IT resources into capabilities that provide
              value to the customers’ missions.{' '}
            </h3>
          </section>
        </SplitTwoToneContainer>

        {/* Expert Capabilities */}
        <SectionContainer id="" color="white" type="pressedEdgeLeft">
          <ExpertCapabilities
            serviceName="Information Technology"
            ServiceIcon={ServiceIcon}
          />
        </SectionContainer>
      </div>
    </GeneralPageLayout>
  )
}

export default InformationTechnologyPage
